import { Record } from "@phosphor-icons/react";
import { useState } from "react";
import { Trans, useTranslation, UseTranslationResponse } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  useChildEventsQuery,
  useParentEventsQuery,
  useVersionsQuery,
} from "../../../api/blockchain/events";
import { useApprovalRuleProposalQuery } from "../../../api/blockchain/users";
import { useCompanyUtils, useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { splitEvents } from "../../../hooks/useCompanyEvents/split-events";
import { getLocale } from "../../../i18n";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { EntitiesMap } from "../../../types/models/entities";
import type {
  CompanyFoundationEvent,
  DecreaseCapitalCancelSharesEvent,
  DecreaseCapitalEvent,
  IncreaseCapitalBonusIssueEvent,
  IncreaseCapitalEvent,
  LedgerApprovalEvent,
  LedgerApprovalInitializedEvent,
  PledgedSharesUpdateEvent,
  ShareAllocationEvent,
  ShareCertificatesUpdateEvent,
  ShareClassUpdateEvent,
  ShareIssueEvent,
  ShareReclassificationByClassEvent,
  ShareReclassificationByRangeEvent,
  ShareSplitEvent,
  ShareTransferEvent,
  TParentEvent,
} from "../../../types/models/events";
import { hiddenLedgerEvents } from "../../../types/models/events";
import type { LedgerVersion } from "../../../types/models/shares";
import {
  getFormattedDate,
  getFormattedLedgerVersion,
} from "../../../utils/date";
import { formatNumber } from "../../../utils/format";
import { hasRequiredPermission } from "../../../utils/permissions";
import { calcSumWithinRange, isRangeValid } from "../../../utils/shares";
import { Button } from "../../design-system/Button";
import {
  CertificateIcon,
  CrossIcon,
  FoundationIcon,
  IncreaseCapitalIcon,
  PledgedIcon,
  ReclassificationIcon,
  ReduceCapitalIcon,
  RestoreIcon,
  ShareClassUpdateIcon,
  ShareIssueIcon,
  SplitIcon,
  TransferIcon,
  TrashIcon,
} from "../../design-system/icons";
import { List, ListItem } from "../../design-system/List";
import { Loading } from "../../design-system/Loading";
import { TableV2 } from "../../design-system/TableV2";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { IconDescription } from "../../IconDescription";
import { RestoreShares } from "../../RestoreShares";
import { useRestrictiveConditionOptions } from "../../ShareTypes/SelectRestrictiveConditions";
import {
  filterEvents,
  getEntityWithFallback,
  isFirstEventInList,
} from "./EventsTable.utils";

const Version = ({ ledgerVersion }: { ledgerVersion?: LedgerVersion }) => {
  const formatedLedgerVersion = getFormattedLedgerVersion(ledgerVersion);
  const date = getFormattedDate(formatedLedgerVersion.date);

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-1 tw-text-sm tw-text-secondary md:tw-block md:tw-min-w-[10rem] md:tw-gap-0">
      {date}
    </div>
  );
};

type ThreeDotChevronUpToggleProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  event: TParentEvent;
  label: string;
  enableExpand: boolean;
  "data-testid"?: string;
};

const ThreeDotChevronUpToggle: React.FunctionComponent<
  ThreeDotChevronUpToggleProps
> = ({
  open,
  onOpenChange,
  event,
  label,
  enableExpand,
  ...props
}: ThreeDotChevronUpToggleProps) => {
  const i18n = useTranslation();
  const { user } = useSession();

  const [isRestoreSharesOpen, setIsRestoreSharesOpen] = useState(false);
  const [isDeleteSharesOpen, setIsDeleteSharesOpen] = useState(false);
  const currentCompany = useCurrentCompany();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const versionsQuery = useVersionsQuery(currentCompany!.orgNumber);
  const handleSharesChange = () => {
    versionsQuery.refetch();
    eventsQuery.refetch();
  };
  const currentVersion = versionsQuery.isSuccess
    ? versionsQuery.data[0]!
    : undefined;

  const pendingApprovalRuleProposalQuery = useApprovalRuleProposalQuery(
    currentCompany!.orgNumber
  );
  const isApprovalPolicyPending =
    pendingApprovalRuleProposalQuery.isSuccess &&
    !!pendingApprovalRuleProposalQuery.data;

  const hasRollbackPending =
    eventsQuery.isSuccess &&
    eventsQuery.data.data.find((e) => e.type === "LedgerRollbackPending");
  const eventsByStatus =
    eventsQuery.isSuccess && splitEvents(eventsQuery.data.data);

  const isDraft = !!(
    eventsByStatus && eventsByStatus.draftEvents.find((i) => i.id === event.id)
  );
  const pendingEvents =
    eventsByStatus &&
    eventsByStatus.pendingEvents.filter(
      (e) => !hiddenLedgerEvents.includes(e.type)
    );
  const approvedEvents =
    eventsByStatus &&
    eventsByStatus.approvedEvents.filter(
      (e) => !hiddenLedgerEvents.includes(e.type)
    );

  const hasPendingEvents = pendingEvents && pendingEvents.length > 0;

  let possibleToRollback = false;
  if (eventsByStatus) {
    if (eventsByStatus.draftEvents.find((i) => i.id === event.id)) {
      possibleToRollback = !isFirstEventInList(
        eventsByStatus.draftEvents,
        event
      );
    } else if (eventsByStatus.pendingEvents.find((i) => i.id === event.id)) {
      possibleToRollback =
        !isFirstEventInList(eventsByStatus.pendingEvents, event) ||
        filterEvents(eventsByStatus.draftEvents).length > 0;
    } else if (eventsByStatus.approvedEvents.find((i) => i.id === event.id)) {
      possibleToRollback =
        !isFirstEventInList(eventsByStatus.approvedEvents, event) ||
        filterEvents(eventsByStatus.draftEvents).length > 0 ||
        filterEvents(eventsByStatus.pendingEvents).length > 0;
    }
  }

  const isFirstPending = !!(
    pendingEvents &&
    pendingEvents.length > 0 &&
    pendingEvents[0]?.id === event.id
  );

  const isFirstApproved = !!(
    approvedEvents &&
    approvedEvents.length > 0 &&
    approvedEvents[0]?.id === event.id
  );

  const hasPendingApproval = hasRollbackPending || hasPendingEvents;
  const enableRollback =
    isDraft ||
    isFirstPending ||
    (!hasPendingApproval && isFirstApproved) ||
    (!hasPendingApproval && !isApprovalPolicyPending);
  const showRollback = possibleToRollback;

  const isCompanyFoundation = event.type === "CompanyFoundation";
  const enableReset =
    isDraft || (!hasPendingApproval && !isApprovalPolicyPending);

  if (!currentCompany) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center" data-testid={props["data-testid"]}>
      {currentCompany &&
        hasRequiredPermission("Editor", currentCompany, user) && (
          <div>
            <div className="tw-flex tw-items-center">
              {showRollback && (
                <div>
                  <TooltipV2
                    content={
                      !enableRollback &&
                      i18n.t(
                        isApprovalPolicyPending
                          ? "initiatePolicy.approvalPolicyRequired"
                          : "approvalPolicy.rollBackLedgerToThisEvent.disabled"
                      )
                    }
                  >
                    <Button
                      disabled={!enableRollback}
                      onClick={() => setIsRestoreSharesOpen(true)}
                      variant="clean"
                    >
                      <RestoreIcon />
                    </Button>
                  </TooltipV2>
                </div>
              )}
              {isCompanyFoundation && (
                <div>
                  <TooltipV2
                    content={
                      !enableReset &&
                      i18n.t(
                        isApprovalPolicyPending
                          ? "initiatePolicy.approvalPolicyRequired"
                          : "approvalPolicy.rollBackLedgerToThisEvent.disabled"
                      )
                    }
                  >
                    <Button
                      disabled={!enableReset}
                      onClick={() => setIsDeleteSharesOpen(true)}
                      variant="clean"
                    >
                      <TrashIcon className="tw-text-error" />
                    </Button>
                  </TooltipV2>
                </div>
              )}
            </div>
          </div>
        )}
      {isRestoreSharesOpen && currentVersion && (
        <RestoreShares
          onClose={() => {
            setIsRestoreSharesOpen(false);
          }}
          currentVersion={currentVersion}
          events={eventsQuery.isSuccess ? eventsQuery.data.data : []}
          version={{ value: event.date, label }}
          currentCompany={currentCompany}
          onSuccess={() => {
            handleSharesChange();
            setIsRestoreSharesOpen(false);
          }}
        />
      )}
      {isDeleteSharesOpen && currentVersion && (
        <RestoreShares
          onClose={() => {
            setIsDeleteSharesOpen(false);
          }}
          currentVersion={currentVersion}
          events={[]}
          version={undefined}
          currentCompany={currentCompany}
          onSuccess={() => {
            handleSharesChange();
            setIsDeleteSharesOpen(false);
          }}
        />
      )}
    </div>
  );
};

const ShareAllocationEventLine = ({
  event,
  entitiesMap,
}: {
  event: ShareAllocationEvent;
  entitiesMap: EntitiesMap;
}) => {
  const { recipient, shares } = event;

  return (
    <Trans
      i18nKey="events.allocation.content"
      values={{
        shares: formatNumber(calcSumWithinRange(shares)),
        start: formatNumber(shares.start),
        end: formatNumber(shares.end),
        recipient: getEntityWithFallback(entitiesMap, recipient).name,
        type: shares.type,
      }}
    />
  );
};

const ShareAllocationEventList = ({
  parentEventId,
  currentCompany,
  entitiesMap,
}: {
  parentEventId: string;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
}) => {
  const childEventsQuery = useChildEventsQuery(
    currentCompany.orgNumber,
    parentEventId
  );
  if (childEventsQuery.isLoading) {
    return <Loading />;
  }
  if (!childEventsQuery.data) {
    return null;
  }

  return (
    <ul className="tw-flex tw-flex-col">
      {childEventsQuery.data.map((e) => (
        <ListItem
          key={e.shares.start}
          className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
        >
          <ShareAllocationEventLine event={e} entitiesMap={entitiesMap} />
        </ListItem>
      ))}
    </ul>
  );
};

const getEventDetails = (
  i18n: UseTranslationResponse<"translation", undefined>,
  formatCurrency: (value: number | string) => string,
  event: TParentEvent,
  currentCompany: CompanyInvolvement | CompanyInformation,
  entitiesMap: EntitiesMap
) => {
  const eventsData = {
    CompanyFoundation: () => {
      const e = event as CompanyFoundationEvent;

      return {
        title: i18n.t("events.companyFoundation.title"),
        icon: <FoundationIcon />,
        description: (
          <Trans
            i18nKey="events.companyFoundation.content"
            values={{
              shares: formatNumber(e.shares.total),
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <ShareAllocationEventList
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            parentEventId={e.id}
          />
        ),
      };
    },
    ShareIssue: () => {
      const e = event as ShareIssueEvent;

      return {
        title: i18n.t("events.issue.title"),
        icon: <ShareIssueIcon />,
        description: (
          <Trans
            i18nKey="events.issue.content"
            values={{
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
        panelContent: (
          <ShareAllocationEventList
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            parentEventId={e.id}
          />
        ),
      };
    },
    ShareSplit: () => {
      const e = event as ShareSplitEvent;
      const diff = e.ratio.x - e.ratio.y;
      const isReverse = diff > 0;

      return {
        title: i18n.t(
          isReverse ? "events.reverseSplit.title" : "events.split.title"
        ),
        icon: isReverse ? <CrossIcon /> : <SplitIcon />,
        description: (
          <Trans
            i18nKey={
              isReverse ? "events.reverseSplit.content" : "events.split.content"
            }
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey={
              isReverse
                ? "events.reverseSplit.content.detailed"
                : "events.split.content.detailed"
            }
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
      };
    },
    ReverseShareSplit: () => {
      const e = event as ShareSplitEvent;

      return {
        title: i18n.t("events.reverseSplit.title"),
        icon: <CrossIcon />,
        description: (
          <Trans
            i18nKey="events.reverseSplit.content"
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reverseSplit.content.detailed"
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
      };
    },
    ShareTransfer: () => {
      const e = event as ShareTransferEvent;
      const { shares } = e;

      if (shares.length > 1) {
        const sharesCount = shares.reduce(
          (prev, curr) => prev + (curr.range.end - curr.range.start + 1),
          0
        );

        return {
          title: i18n.t("events.transfer.title"),
          icon: <TransferIcon />,
          description: (
            <Trans
              i18nKey="events.transfer.content.basic"
              values={{ shares: formatNumber(sharesCount) }}
            />
          ),
          panelContent: (
            <List>
              {shares.map(({ range, sender, recipient }) => (
                <ListItem
                  key={range.start}
                  className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
                >
                  <Trans
                    i18nKey="events.transfer.content.detailed"
                    values={{
                      start: formatNumber(range.start),
                      end: formatNumber(range.end),
                      shares: formatNumber(calcSumWithinRange(range)),
                      sender: getEntityWithFallback(entitiesMap, sender).name,
                      recipient: getEntityWithFallback(entitiesMap, recipient)
                        .name,
                      type: range.type,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ),
        };
      }

      const [{ sender, recipient, range }] = shares;

      return {
        title: i18n.t("events.transfer.title"),
        icon: <TransferIcon />,
        description: (
          <Trans
            i18nKey="events.transfer.content"
            values={{
              start: formatNumber(range.start),
              end: formatNumber(range.end),
              shares: formatNumber(calcSumWithinRange(range)),
              sender: getEntityWithFallback(entitiesMap, sender).name,
              recipient: getEntityWithFallback(entitiesMap, recipient).name,
              type: range.type,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.transfer.content.detailed"
            values={{
              start: formatNumber(range.start),
              end: formatNumber(range.end),
              shares: formatNumber(calcSumWithinRange(range)),
              sender: getEntityWithFallback(entitiesMap, sender).name,
              recipient: getEntityWithFallback(entitiesMap, recipient).name,
              type: range.type,
            }}
          />
        ),
      };
    },
    DecreaseCapital: () => {
      const e = event as DecreaseCapitalEvent;

      return {
        title: i18n.t("events.reduceCapital.title"),
        icon: <ReduceCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.reduceCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reduceCapital.content.detailed"
            values={{
              capital: formatCurrency(e.shares.capital),
              quotaBefore: formatCurrency(e.shares.quotaValue.before),
              quotaAfter: formatCurrency(e.shares.quotaValue.after),
            }}
          />
        ),
      };
    },
    DecreaseCapitalCancelShares: () => {
      const e = event as DecreaseCapitalCancelSharesEvent;

      return {
        title: i18n.t("events.reduceCapital.title"),
        icon: <ReduceCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.reduceCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reduceCapital.cancel.content"
            values={{
              capital: formatCurrency(e.shares.capital),
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
      };
    },
    IncreaseCapital: () => {
      const e = event as IncreaseCapitalEvent;

      return {
        title: i18n.t("events.increaseCapital.title"),
        icon: <IncreaseCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.increaseCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.increaseCapital.content.detailed"
            values={{
              capital: formatCurrency(e.shares.capital),
              quotaBefore: formatCurrency(e.shares.quotaValue.before),
              quotaAfter: formatCurrency(e.shares.quotaValue.after),
            }}
          />
        ),
      };
    },
    IncreaseCapitalBonusIssue: () => {
      const e = event as IncreaseCapitalBonusIssueEvent;

      return {
        title: i18n.t("events.increaseCapital.title"),
        icon: <IncreaseCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.increaseCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
        panelContent: (
          <ShareAllocationEventList
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            parentEventId={e.id}
          />
        ),
      };
    },
    ReclassifySharesByRange: () => {
      const e = event as ShareReclassificationByRangeEvent;
      const sharesCount = e.shares.ranges.reduce(
        (prev, curr) => prev + calcSumWithinRange(curr),
        0
      );

      return {
        title: i18n.t("events.reclassification.title"),
        icon: <ReclassificationIcon />,
        description: (
          <Trans
            i18nKey="events.reclassification.range.content"
            values={{
              shares: formatNumber(sharesCount),
              toClass: e.shares.toClass,
            }}
          />
        ),
        panelContent: (
          <List>
            {e.shares.ranges.map((range) => (
              <ListItem
                key={range.start}
                className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
              >
                <Trans
                  i18nKey="events.reclassification.range.child.content"
                  values={{
                    shares: formatNumber(calcSumWithinRange(range)),
                    start: formatNumber(range.start),
                    end: formatNumber(range.end),
                    fromClass: range.type,
                    toClass: e.shares.toClass,
                  }}
                />
              </ListItem>
            ))}
          </List>
        ),
      };
    },
    ReclassifySharesByClass: () => {
      const e = event as ShareReclassificationByClassEvent;

      return {
        title: i18n.t("events.reclassification.title"),
        icon: <ReclassificationIcon />,
        description: (
          <Trans
            i18nKey="events.reclassification.class.content"
            values={{
              shares: formatNumber(e.shares.total),
              toClass: e.shares.toClass,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reclassification.class.content.detailed"
            values={{
              shares: formatNumber(e.shares.total),
              fromClass: e.shares.fromClass,
              toClass: e.shares.toClass,
            }}
          />
        ),
      };
    },
    ShareClassUpdate: () => {
      const e = event as ShareClassUpdateEvent;
      const { shareClass } = e;
      const conditionOptions = useRestrictiveConditionOptions();
      const enabledConditions = conditionOptions.filter(
        (option) => !!shareClass.condition[option.value]
      );

      return {
        title: i18n.t("events.shareTypeUpdate.title"),
        icon: <ShareClassUpdateIcon />,
        description: (
          <Trans
            i18nKey="events.shareTypeUpdate.content"
            values={{
              name: shareClass.name,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.shareTypeUpdate.content.detailed"
            values={{
              name: shareClass.name,
              count: enabledConditions.length,
              conditions: enabledConditions
                .map((condition) => condition.label)
                .join(", "),
            }}
          />
        ),
      };
    },
    ShareCertificateUpdate: () => {
      const e = event as ShareCertificatesUpdateEvent;
      const {
        ranges: { before, after },
      } = e;
      const removed = before.filter(
        (x) =>
          !after.find((range) => x.start === range.start && x.end === range.end)
      );
      const added = after.filter(
        (x) =>
          !before.find(
            (range) => x.start === range.start && x.end === range.end
          )
      );

      return {
        title: i18n.t("events.shareCertificateUpdate.title"),
        icon: <CertificateIcon />,
        description: (
          <Trans
            i18nKey="events.shareCertificateUpdate.content"
            values={{ count: after.length }}
          />
        ),
        panelContent: (
          <List>
            {added.map(({ start, end, type }) => (
              <ListItem
                key={start}
                className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.shareCertificateUpdate.content.added", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                })}
              </ListItem>
            ))}
            {removed.map(({ start, end, type }) => (
              <ListItem
                key={start}
                className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.shareCertificateUpdate.content.removed", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                })}
              </ListItem>
            ))}
          </List>
        ),
      };
    },
    SharePledgedUpdate: () => {
      const e = event as PledgedSharesUpdateEvent;
      const {
        ranges: { before, after },
      } = e;
      const removed = before.filter(
        (x) =>
          !after.find((range) => x.start === range.start && x.end === range.end)
      );
      const added = after.filter(
        (x) =>
          !before.find(
            (range) => x.start === range.start && x.end === range.end
          )
      );

      return {
        title: i18n.t("events.pledgedSharesUpdate.title"),
        icon: <PledgedIcon />,
        description: (
          <Trans
            i18nKey="events.pledgedSharesUpdate.content"
            values={{ count: after.length }}
          />
        ),
        panelContent: (
          <List>
            {added.map(({ start, end, type, creditor }) => (
              <ListItem
                key={start}
                className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.pledgedSharesUpdate.content.added", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                  creditor: getEntityWithFallback(entitiesMap, creditor).name,
                })}
              </ListItem>
            ))}
            {removed.map(({ start, end, type }) => (
              <ListItem
                key={start}
                className="tw-space-y-0 tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.pledgedSharesUpdate.content.removed", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                })}
              </ListItem>
            ))}
          </List>
        ),
      };
    },
    LedgerRollbackPending: () => {
      const {
        user: { id: userId },
      } = event as LedgerApprovalInitializedEvent;
      const initiator: string = entitiesMap[userId]?.name ?? "";

      return {
        title: i18n.t("events.ledgerRollbackPending.title"),
        icon: <RestoreIcon />,
        description: initiator ? (
          <Trans
            i18nKey="events.initializeLedgerApproval.content"
            values={{ initiator }}
          />
        ) : (
          ""
        ),
        panelContent: undefined,
      };
    },
    LedgerApprovalInitialized: undefined,
    LedgerApproval: undefined,
    LedgerRollback: undefined,
    LedgerPolicyApproval: undefined,
    LedgerRollbackRejected: undefined,
    LedgerApprovalRejected: undefined,
  };

  const matchingEventCallable = eventsData[event.type];
  if (matchingEventCallable) {
    return matchingEventCallable();
  }
  return undefined;
};

const EventsTable = ({
  events,
  currentCompany,
  entitiesMap,
  expandedEvents,
  setExpandedEvents,
}: {
  events: (TParentEvent & { label: string; approval?: LedgerApprovalEvent })[];
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
  expandedEvents: { [key: string]: boolean };
  setExpandedEvents: (value: { [key: string]: boolean }) => void;
}) => {
  const i18n = useTranslation();
  const { formatCurrency } = useCompanyUtils();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const expandedEventIndexes = events.reduce(
    (prev, curr, i) => ({ ...prev, [i]: expandedEvents[curr.id] || false }),
    {} as Record<number, boolean>
  );
  const setExpandedEventIndexes = (value: Record<number, boolean>) => {
    const newExpandedEvents = Object.entries(value).reduce(
      (prev, [k, v]) => ({ ...prev, [events[parseInt(k, 10)]!.id]: v }),
      {} as Record<string, boolean>
    );
    setExpandedEvents(newExpandedEvents);
  };

  const rows = events
    .map((event) => {
      const details = getEventDetails(
        i18n,
        formatCurrency,
        event,
        currentCompany,
        entitiesMap
      );
      const user = "user" in event ? entitiesMap[event.user.id] : undefined;
      const approvingUsers =
        event.approval &&
        (event.approval.version === "1"
          ? [event.approval.user]
          : event.approval.users);
      const approvers = approvingUsers
        ? approvingUsers
            .map(({ id }) => entitiesMap[id])
            .filter((x) => x !== undefined)
        : [];
      if (!details) {
        return null;
      }
      return {
        key: event.id,
        date: <Version ledgerVersion={event.date} />,
        event: (
          <IconDescription
            className="max-sm:tw-gap-2"
            description={
              <div className="max-sm:tw-hidden">{details.description}</div>
            }
            icon={details.icon}
            title={details.title}
            titleWeight="medium"
          />
        ),
        description: details.panelContent,
        approval:
          event.approval && approvers.length > 0
            ? `${approvers
                .map((a) => a.name)
                .sort()
                .join(", ")} (${new Date(event.approval.created).toLocaleString(
                getLocale()
              )})`
            : undefined,
        createdBy:
          user &&
          `${user.name} (${new Date(event.created).toLocaleString(
            getLocale()
          )})`,
        actions: (
          <div className="tw-flex tw-justify-end">
            <ThreeDotChevronUpToggle
              data-testid="event-line-menu"
              open
              onOpenChange={(value: boolean) => {
                setExpandedEvents({
                  ...expandedEvents,
                  [event.id]: value,
                });
              }}
              event={event}
              label={event.label}
              enableExpand={!!details.panelContent}
            />
          </div>
        ),
      };
    })
    .filter((x) => x !== null);

  return (
    <TableV2
      columns={[
        {
          name: "date",
          title: i18n.t("label.date"),
          key: true,
          sortable: false,
        },
        {
          name: "event",
          title: i18n.t("label.event"),
          key: true,
          sortable: false,
        },
        {
          name: "description",
          title: i18n.t("label.description"),
        },
        {
          name: "approval",
          title: i18n.t("events.approvedBy"),
        },
        {
          name: "createdBy",
          title: i18n.t("events.createdBy"),
        },
        {
          name: "actions",
          title: "",
          key: !isTabletOrMobileDevice,
        },
      ]}
      data={rows}
      expandedRows={expandedEventIndexes}
      setExpandedRows={setExpandedEventIndexes}
    />
  );
};

export { EventsTable };

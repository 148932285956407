import { useMediaQuery } from "react-responsive";

import { clsxm } from "../../../utils/tailwind";
import { Description } from "../Description";
import { ChevronDownIcon, ChevronUpIcon } from "../icons";
import { Column } from "./Column";

type ColumnProps = {
  name: string;
  title: string;
  key?: boolean;
  className?: string;
  colspan?: number;
  sortable?: boolean;
};

type TableProps = React.HTMLAttributes<HTMLSpanElement> & {
  columns: ColumnProps[];
  data: {
    key: string;
    [key: string]: JSX.Element | JSX.Element[] | string | undefined;
  }[];
  sortBy?: string;
  setSortBy?: (value: string) => void;
  expandedRows: Record<number, boolean>;
  setExpandedRows: (data: Record<number, boolean>) => void;
};

const Card = ({
  columns,
  item,
  expanded,
  setExpanded,
}: {
  columns: ColumnProps[];
  item: { [key: string]: JSX.Element | JSX.Element[] | string | undefined };
  expanded: boolean;
  setExpanded: (value: boolean) => void;
}) => {
  return (
    <button
      type="button"
      id="mobile"
      className="tw-relative tw-w-full tw-rounded tw-p-4 tw-text-left"
      style={{
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <div
        className={clsxm(
          "tw-flex tw-flex-col tw-gap-0 tw-transition-all tw-duration-500 tw-ease-in-out",
          { "tw-gap-4": expanded }
        )}
      >
        <div className="tw-flex tw-justify-between">
          {columns
            .filter((c) => c.key)
            .map(({ name }) => (
              <div key={name}>{item[name]}</div>
            ))}
        </div>
        <div
          className={clsxm(
            "tw-flex tw-max-h-0 tw-flex-col tw-gap-6 tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out",
            { "tw-max-h-[1000px]": expanded }
          )}
        >
          {columns
            .filter((c) => !c.key && item[c.name])
            .map(({ name, title }) =>
              title ? (
                <Description
                  key={title}
                  title={title}
                  description={item[name]}
                />
              ) : (
                item[name]
              )
            )}
        </div>
      </div>
    </button>
  );
};

const Row = ({
  columns,
  item,
  expanded,
  setExpanded,
}: {
  columns: ColumnProps[];
  item: { [key: string]: JSX.Element | JSX.Element[] | string | undefined };
  expanded: boolean;
  setExpanded: (value: boolean) => void;
}) => {
  const hasChildContent =
    columns.filter((c) => !c.key && item[c.name]).length > 0;
  return (
    <>
      <tr className="tw-border-t">
        {columns
          .filter((c) => c.key)
          .map(({ name }) => (
            <td className="tw-px-6 tw-py-4 tw-font-normal" key={name}>
              {item[name]}
            </td>
          ))}
        {hasChildContent && (
          <td>
            <button
              type="button"
              onClick={() => setExpanded(!expanded)}
              className="tw-py-2.5"
              data-testid="expand-row-button"
            >
              {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </button>
          </td>
        )}
      </tr>
      {expanded && hasChildContent && (
        <tr>
          <td colSpan={3}>
            <div className="tw-flex tw-flex-col tw-gap-6 tw-px-6 tw-py-4">
              {columns
                .filter((c) => !c.key && item[c.name])
                .map(({ name, title }) =>
                  title ? (
                    <Description
                      key={title}
                      title={title}
                      description={item[name]}
                    />
                  ) : (
                    item[name]
                  )
                )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const TableV2 = ({
  columns,
  data,
  sortBy,
  setSortBy,
  expandedRows,
  setExpandedRows,
}: TableProps) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const setExpandedRow = (index: number, value: boolean) => {
    setExpandedRows({ ...expandedRows, [index]: value });
  };

  if (isTabletOrMobileDevice) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2 max-md:tw-px-4">
        {data.map((item, i) => (
          <Card
            columns={columns}
            item={item}
            key={item.key}
            expanded={!!expandedRows[i]}
            setExpanded={(value) => setExpandedRow(i, value)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="tw-relative tw-overflow-x-auto">
      <table data-testid="share-blocks" className="tw-w-full">
        <thead className="tw-text-left">
          <tr className="tw-border-b">
            {columns
              .filter((c) => c.key)
              .map(({ name, title, sortable, colspan, className }) => (
                <th
                  scope="col"
                  className={clsxm(
                    "tw-px-6 tw-py-2.5 tw-font-normal",
                    className
                  )}
                  key={name}
                  colSpan={colspan || 1}
                >
                  {name && (
                    <Column
                      title={title}
                      sortDesc={sortBy === `${name}-desc`}
                      sortAsc={sortBy === `${name}-asc`}
                      onClick={() => {
                        if (setSortBy) {
                          setSortBy(
                            sortBy === `${name}-asc`
                              ? `${name}-desc`
                              : `${name}-asc`
                          );
                        }
                      }}
                      sortable={sortable !== undefined ? sortable : true}
                    />
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <Row
              columns={columns}
              item={item}
              key={item.key}
              expanded={!!expandedRows[i]}
              setExpanded={(value) => setExpandedRow(i, value)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { TableV2 };
export type { TableProps };

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { clsxm } from "../../../utils/tailwind";

type ThemeType =
  | "default"
  | "kvanta"
  | "disabled"
  | "grayBlack"
  | "purple"
  | "whiteGray";
type FontWeightType = keyof typeof fontWeightClass;
export type FontSizeType = keyof typeof fontSizeClass;

type DescriptionProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  align?: "left" | "right";
  theme?: ThemeType;
  size?: FontSizeType;
  titleSize?: FontSizeType;
  descriptionSize?: FontSizeType;
  titleWeight?: FontWeightType;
  descriptionWeight?: FontWeightType;
  descriptionCollapse?: boolean;
  className?: string;
};

const alignClass = { left: "", right: "tw-text-right" } as const;
const themeClass = {
  default: {
    body: "tw-text-body",
    secondary: "tw-text-secondary",
  },
  kvanta: {
    body: "tw-text-kvanta-primary",
    secondary: "tw-text-kvanta-secondary",
  },
  disabled: {
    body: "tw-text-body tw-opacity-50",
    secondary: "tw-text-secondary tw-opacity-50",
  },
  grayBlack: {
    body: "tw-text-secondary",
    secondary: "tw-text-black",
  },
  purple: {
    body: "tw-text-kvanta-purple-dark",
    secondary: "tw-text-kvanta-purple-dark",
  },
  whiteGray: {
    body: "tw-text-white",
    secondary: "tw-text-neutral-400",
  },
} as const;

const fontSizeClass = {
  sm: "tw-text-sm",
  base: "tw-text-base",
  lg: "tw-text-lg",
  xl: "tw-text-xl",
  xxl: "tw-text-2xl",
} as const;

const fontWeightClass = {
  normal: "tw-font-normal",
  medium: "tw-font-medium",
  semibold: "tw-font-semibold",
  bold: "tw-font-bold",
} as const;

const Description = ({
  title,
  description,
  align = "left",
  theme = "default",
  size = "sm",
  titleSize,
  descriptionSize,
  titleWeight = "normal",
  descriptionWeight = "normal",
  descriptionCollapse,
  className,
}: DescriptionProps) => {
  const i18n = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <dl data-testid={`description-item-${title}`}>
      <dt
        className={clsxm(
          "tw-leading-5 tw-text-body",
          fontSizeClass[titleSize || size],
          fontWeightClass[titleWeight],
          alignClass[align],
          themeClass[theme].body,
          className
        )}
      >
        {title}
      </dt>
      {description && (
        <dd
          className={clsxm(
            "tw-text-secondary",
            fontSizeClass[descriptionSize || size],
            fontWeightClass[descriptionWeight],
            alignClass[align],
            themeClass[theme].secondary
          )}
        >
          {descriptionCollapse ? (
            <>
              {isExpanded && description}
              <button
                type="button"
                className="tw-block tw-text-blue-500 hover:tw-text-blue-700"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {i18n.t(isExpanded ? "label.readLess" : "label.readMore")}
              </button>
            </>
          ) : (
            description
          )}
        </dd>
      )}
    </dl>
  );
};

export { Description };
export type { DescriptionProps, ThemeType };

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { convertErrorToTi18nKey } from "../../../api";
import { useShareTypesQuery } from "../../../api/blockchain/company";
import { useIssueShares } from "../../../api/rest/events";
import { CrossIcon } from "../../../components/design-system/icons";
import { notify } from "../../../components/design-system/Notifications";
import { PageWrapper } from "../../../components/PageWrapper";
import { useStepper } from "../../../hooks/useStepper";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { draftName } from "./constants";
import InvestmentAmount from "./InvestmentAmount";
import ShareBlocks from "./ShareBlocks";
import ShareClasses from "./ShareClasses";
import type { FormChange, FormState } from "./types";

const NUMBER_OF_STEPS = 3;

type IssueSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};
const IssueShares = ({ currentCompany }: IssueSharesProps) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const navigate = useNavigate();
  const {
    currentStep,
    handleNext: handleStepSuccess,
    handlePrevious: handleStepCancel,
  } = useStepper(NUMBER_OF_STEPS);
  const [form, setForm] = useState<FormState>({
    shareCapital: {},
    shareClasses: [],
    shareBlocks: [],
  });

  const handleFormChange = (values: FormChange) => {
    setForm((prev) => ({ ...prev, ...values }));
  };

  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "", {
    onSuccess: (data) => {
      handleFormChange({
        shareClasses: data.map((type) => ({ ...type, numberOfShares: 0 })),
      });
    },
  });

  const issueSharesMutation = useIssueShares(
    currentCompany.orgNumber,
    draftName,
    {
      onSuccess: () => {
        notify(
          i18n.t("events.success", { name: i18n.t("events.issue.title") }),
          { type: "success" }
        );
        navigate(eventsPath);
      },
      onError: (error) => {
        notify(i18n.t(convertErrorToTi18nKey(error)), { type: "error" });
      },
    }
  );

  const descriptionList = [
    i18n.t("events.issue.step1.description"),
    i18n.t("events.issue.step2.description"),
    i18n.t("events.issue.step3.description"),
  ];

  return (
    <PageWrapper data-testid="issue-shares">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>
            {`${i18n.t("label.step")} ${currentStep + 1} ${i18n.t(
              "label.of"
            )} ${NUMBER_OF_STEPS} - ${i18n.t("events.issue.title")}`}
          </h4>
          <div className="tw-text-secondary">
            {descriptionList[currentStep]}
          </div>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <div>
        {currentStep === 0 && (
          <InvestmentAmount
            currentCompany={currentCompany}
            initialData={form}
            onFormChange={handleFormChange}
            onSuccess={handleStepSuccess}
          />
        )}
        {currentStep === 1 && (
          <ShareClasses
            existingShareClasses={shareTypesQuery.data || []}
            currentCompany={currentCompany}
            initialData={form}
            onFormChange={handleFormChange}
            onSuccess={handleStepSuccess}
            onCancel={handleStepCancel}
          />
        )}
        {currentStep === 2 && (
          <ShareBlocks
            currentCompany={currentCompany}
            initialData={form}
            onFormChange={handleFormChange}
            onSuccess={() => {
              issueSharesMutation.mutate();
            }}
            onCancel={handleStepCancel}
            isLoading={issueSharesMutation.isLoading}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export { IssueShares };
